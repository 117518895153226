import React from 'react';
import bg_header from '../../image/bg_header.png'

const Header = () => {
    return (
        <div>
            <div className={'container max-w-[900px] mx-auto'}>
                <img src={bg_header} className={`w-full`} alt=""/>
            </div>
        </div>
    );
};

export default Header;