import {combineReducers, configureStore} from "@reduxjs/toolkit";
import mainReducer from "./reducers/main-reducer";
import {reducer as formReducer} from 'redux-form'

const rootReducer = combineReducers({
    main: mainReducer,
    form: formReducer
})

export const store = configureStore({
    reducer: rootReducer
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch