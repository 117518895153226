const Radio = ({input, label, meta}: any) => {

    const checkRadio = meta.touched && meta.error && true

    return (
        <div className={'flex flex-col items-center lg:mx-8 mx-4'}>
            <div className={`text-2xl`}>{label}</div>
            <label className={`w-6 h-6 ${checkRadio ? 'border-red-300' : 'border-aqua'} border-2 relative block rounded-full radio`}>
                <input className={'hidden'} {...input} type="radio"/>
                <div className={`h-3 w-3 bg-aqua rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 fakeInput`}></div>
            </label>
        </div>
    );
};

export default Radio;