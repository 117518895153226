import React from 'react';

const Input = ({input, label, meta}: any) => {

    const errors = (meta.touched && meta.error) && true

    return (
        <>
            <div className={'rounded-md inline-block relative mt-4 w-full border-b-gray border-b-2'}>
                <input placeholder={''} className={`w-full block px-4 py-2 border-none outline-0 text-xl outline-none`} {...input}
                       type="text"/>
            </div>

            {errors && <div className={`text-red-300 text-xl mt-4`}>{meta.error}</div>}
        </>
    );
};

export default Input;