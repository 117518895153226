import validator from "validator";

export const requiredField = (value: string): string | undefined => {
    if(value)
        return undefined

    return 'Заполните поле'
}

export const minLength = (min:number) => (value:string) => {
    if(value)
        if(!validator.isLength(value, { min: min}))
            return `Минимальная длина должна быть не менее ${min} символов`

    return undefined
}

