import {createSlice} from "@reduxjs/toolkit";

const initialState: string[] = []

const mainSlide = createSlice({
    name: '@main',
    initialState,
    reducers: {

    }
})

export default mainSlide.reducer
export const {

} = mainSlide.actions