import React, {useState} from 'react';
import Header from "./components/Header/Header";
import FormQuestionReduxForm from "./components/FormQuestions/FormQuations";
import bg_footer from './image/bg_footer.png'
import {IDataAboutForm} from "./type/data_about_form";
import axios from "axios";
import ModalAlert from "./components/ModalAlert/ModalAlert";

function App() {
    const queryParams = new URLSearchParams(window.location.search);
    const [modalSuccess, setModalSuccess] = useState<boolean>(false)

    const getDataAboutForm = async (data_about_form: IDataAboutForm) => {

        const data = `
            1) На сколько удобно бронирование на сайте?: <b>${data_about_form.question1}</b>, <br> 
            2) Оцените мероприятие: <b>${data_about_form.question2}</b>,<br>
            3) Оцените работу ведущего: <b>${data_about_form.question3}</b>,<br>
            4) Расскажите, что вам понравилось на мероприятии?: <b>${data_about_form.question4}</b>,<br>
            5) Есть ли то, что вам не понравилось? Какие есть замечания?: <b>${data_about_form.question5}</b>,<br>
            6) Каких мероприятий и развлечений вам не хватает в городе?: <b>${data_about_form.question6}</b> <br>
            7) Оцените маршрут мероприятия : <b>${data_about_form.question7}</b>
        `

        const total_evaluation = (typeof data_about_form.question1 === 'string' && typeof data_about_form.question2 === 'string' && typeof data_about_form.question3 === 'string' && typeof data_about_form.question7 === 'string') && parseInt(data_about_form.question2) + parseInt(data_about_form.question3) + parseInt(data_about_form.question7)

        await axios({
            url: 'https://million-kilometrov.bitrix24.ru/rest/6/1dvf1xm22o75iir0/crm.deal.update',
            method: 'post',
            data: {
                id: queryParams.get('id'),
                fields: {
                    COMMENTS: data,
                    UF_CRM_1670842442872: (typeof total_evaluation === 'number') ? Math.floor((total_evaluation / 3)) : 0
                }
            }
        })

        setModalSuccess(true)

        setTimeout(() => {
            setModalSuccess(false)
            window.close()
        }, 4000)
    }
    return (
        <div className="App my-12 bg-white lg:px-0 px-2">
            {modalSuccess && <ModalAlert/>}
            <Header/>
            <div className={`text-center max-w-[900px] mx-auto lg:text-4xl text-2xl my-8 font-bold`}>Здравствуйте, оцените нашу работу. </div>
            <div className={`container max-w-[900px] mx-auto`}>
                <FormQuestionReduxForm onSubmit={getDataAboutForm}/>
            </div>
            <img src={bg_footer} className={`w-full max-w-[900px] container mx-auto`} alt=""/>
        </div>
    );
}

export default App;
