import {Field, reduxForm} from "redux-form";
import {minLength, requiredField} from "../../utils/validations/validators";
import Input from "../UI/Input";
import Radio from "../UI/Radio";

const minLength3 = minLength(3)

const FormQuestion = ({handleSubmit}:any) => {

    return (
        <form onSubmit={handleSubmit}>

            <div className={'bg-white rounded-md border p-2 mt-12 px-10 py-10'}>
                <div className={'text-2xl text-center'}>На сколько удобно бронирование на сайте?<br/>1 - возникли сложности, <br/>5 - очень удобно</div>

                <div className={`flex justify-center mt-10`}>
                    <Field validate={[requiredField]} name={'question1'} type={'radio'} label={'1'} component={Radio} value={'1'} />
                    <Field validate={[requiredField]} name={'question1'} type={'radio'} label={'2'} component={Radio} value={'2'} />
                    <Field validate={[requiredField]} name={'question1'} type={'radio'} label={'3'} component={Radio} value={'3'} />
                    <Field validate={[requiredField]} name={'question1'} type={'radio'} label={'4'} component={Radio} value={'4'} />
                    <Field validate={[requiredField]} name={'question1'} type={'radio'} label={'5'} component={Radio} value={'5'} />
                </div>
            </div>

            <div className={'bg-white rounded-md border p-2 mt-12 px-10 py-10'}>
                <div className={'text-2xl text-center'}>Оцените мероприятие <br/> 1 - не понравилось, <br/>5 - очень понравилось</div>

                <div className={`flex justify-center mt-10`}>
                    <Field validate={[requiredField]} name={'question2'} type={'radio'} label={'1'} component={Radio} value={'1'} />
                    <Field validate={[requiredField]} name={'question2'} type={'radio'} label={'2'} component={Radio} value={'2'} />
                    <Field validate={[requiredField]} name={'question2'} type={'radio'} label={'3'} component={Radio} value={'3'} />
                    <Field validate={[requiredField]} name={'question2'} type={'radio'} label={'4'} component={Radio} value={'4'} />
                    <Field validate={[requiredField]} name={'question2'} type={'radio'} label={'5'} component={Radio} value={'5'} />
                </div>
            </div>

            <div className={'bg-white rounded-md border p-2 mt-12 px-10 py-10'}>
                <div className={'text-2xl text-center'}>Оцените работу ведущего. <br/> 1 - не понравилось, <br/>5 - очень понравилось</div>

                <div className={`flex justify-center mt-10`}>
                    <Field validate={[requiredField]} name={'question3'} type={'radio'} label={'1'} component={Radio} value={'1'} />
                    <Field validate={[requiredField]} name={'question3'} type={'radio'} label={'2'} component={Radio} value={'2'} />
                    <Field validate={[requiredField]} name={'question3'} type={'radio'} label={'3'} component={Radio} value={'3'} />
                    <Field validate={[requiredField]} name={'question3'} type={'radio'} label={'4'} component={Radio} value={'4'} />
                    <Field validate={[requiredField]} name={'question3'} type={'radio'} label={'5'} component={Radio} value={'5'} />
                </div>
            </div>

            <div className={'bg-white rounded-md border p-2 mt-12 px-10 py-10'}>
                <div className={'text-2xl text-center'}>Оцените маршрут мероприятия. <br/> 1 - не понравилось, <br/>5 - очень понравилось</div>

                <div className={`flex justify-center mt-10`}>
                    <Field validate={[requiredField]} name={'question7'} type={'radio'} label={'1'} component={Radio} value={'1'} />
                    <Field validate={[requiredField]} name={'question7'} type={'radio'} label={'2'} component={Radio} value={'2'} />
                    <Field validate={[requiredField]} name={'question7'} type={'radio'} label={'3'} component={Radio} value={'3'} />
                    <Field validate={[requiredField]} name={'question7'} type={'radio'} label={'4'} component={Radio} value={'4'} />
                    <Field validate={[requiredField]} name={'question7'} type={'radio'} label={'5'} component={Radio} value={'5'} />
                </div>
            </div>

            <div className={'bg-white rounded-md border p-2 mt-12 px-10 py-10'}>
                <div className={'text-2xl text-center'}>Расскажите, что вам понравилось на мероприятии?</div>
                <Field label={'Оцените на сколько удобно бронирование на сайте'} name={'question4'} component={Input} />
            </div>

            <div className={'bg-white rounded-md border p-2 mt-12 px-10 py-10'}>
                <div className={'text-2xl text-center'}>Есть ли то, что вам не понравилось? Какие есть замечания?</div>
                <Field label={'Есть ли то, что вам не понравилось? Какие есть замечания?'} name={'question5'} component={Input} />
            </div>

            <div className={'bg-white rounded-md border p-2 mt-12 px-10 py-10'}>
                <div className={'text-2xl text-center'}>Каких мероприятий и развлечений вам не хватает в городе?</div>
                <Field label={'Каких экскурсий и развлечений вам не хватает в городе?'} name={'question6'} component={Input} />
            </div>


            <button className={`bg-gold hover:scale-105 duration-300 text-white py-2 w-[200px] mt-12 cursor-pointer font-bold uppercase mx-auto block`} onSubmit={handleSubmit}>Отправить</button>
        </form>
    );
};

const FormQuestionReduxForm = reduxForm({
    form: 'default'
})(FormQuestion)

export default FormQuestionReduxForm;