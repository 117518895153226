import React from 'react';

const ModalAlert = () => {
    return (
        <div className={`w-4/5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[700px] fixed bg-green z-20`}>
            <div className={`text-center text-white text-4xl px-4 py-10`}>
                Спасибо за ваши ответы!
            </div>
        </div>
    );
};

export default ModalAlert;